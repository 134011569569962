<template>
    <div class="saadetail_box">
        <div>
            <el-button type="primary" @click="update" class="detail_button">返回上一级</el-button>
        </div>
        <div id="maps" ref="mapBox" class="mapBox" :style="mapStyle" v-if="isShow"></div>
        <jy-table :data="dataList" header-cell-class-name="bg_color" border>
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column prop="companyName" label="公司"></jy-table-column>
            <jy-table-column prop="motorcadeName" label="车队"></jy-table-column>
            <jy-table-column prop="routeName" label="线路"></jy-table-column>
            <jy-table-column prop="dirLabel" label="上下行"></jy-table-column>
            <jy-table-column prop="vehicleNo" label="车牌号"></jy-table-column>
            <jy-table-column prop="driverName" label="司机"></jy-table-column>
            <jy-table-column prop="warningTime" label="预警日期"></jy-table-column>
            <jy-table-column prop="warningType" label="预警类型"></jy-table-column>
            <jy-table-column prop="settingTimeLen" label="设定时长"></jy-table-column>
            <jy-table-column prop="inSiteTime" label="进站时间"></jy-table-column>
            <jy-table-column prop="outSiteTime" label="出站时间"></jy-table-column>
            <jy-table-column prop="actualTimeLen" label="实际时长"></jy-table-column>
            <jy-table-column prop="siteName" label="站点名称"></jy-table-column>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
        </jy-pagination>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isShow: true,
            pageSize: 10,
            total: 100,
            pageIndex: 1,
            dataList: [{
                companyName: '',
                motorcadeName: '',
                routeName: '',
                dirLabel: '',
                vehicleNo: '',
                driverName: '',
                warningTime: '',
                warningType: '',
                settingTimeLen: '',
                inSiteTime: '',
                outSiteTime: '',
                actualTimeLen: '',
                siteName: ''
            }],
            mapStyle: {}
        }
    },
    methods: {
        setObserver() {
            let myObserve = new ResizeObserver(() => {
                this.isShow = false
                this.getWidth()
            })
            myObserve.observe(this.$el)
        },
        // 地图
        getMap() {
            this.$nextTick(() => {
                var mapRef = this.$refs.mapBox
                var map = new BMap.Map(mapRef)
                var point = new BMap.Point(116.404, 39.915)
                map.centerAndZoom(point, 15)
            })
        },
        getWidth() {
            this.isShow = true
            var mapRef = this.$refs.mapBox
            var w = $(mapRef).width()
            var h = w * 0.35
            this.mapStyle = {
                height: h + 'px'
            }
            this.getMap()
        },

        init(row) {
            this.getList(row)
            this.$nextTick(() => {
                this.setObserver()
                this.getWidth()
            })
        },
        getList(row) {
            let url = '/statstrandskipsitedaily/queryStrandSkipSiteDetail'
            let option = {
                ...row,
                pageSize: this.pageSize,
                pageIndex: this.pageIndex
            }
            this.$http.post(url, option).then(({ detail }) => {
                this.dataList = detail.list
                this.total = detail.total
            })
        },
        update() {
            this.$emit('update')
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        }
    }
}

</script>
<style lang="scss" scoped="saadetails_box">
.saadetail_box {
    .mapBox {
        width: 100%;
    }
}

</style>
