<template>
	<div>
		<jy-dialog :title="title" :visible.sync="dialogVisible" custom-class="dialog_tree" :width="global.dialogTreeWidth">
			<el-tree v-if="dialogVisible" lazy :props="defaultProps" :load="loadNode" @node-click="save"></el-tree>
		</jy-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				institutionsList: [],
                defaultProps: {
                    // children: "children",
                    label: "orgNa",
                },
			};
		},
		props: {
			title: {
				type: String,
				default: "",
			},
            disableOrgNum: {
				type: [Number, String],
				default: "",
			},
			selectType: {
				type: [Number, String, Array],
				default: "",
			},
            showType:{
                type: [Number, String, Array],
				default:() => {
                    return ['0','1','2']
                }
            },
		},
		activated() {
            if (typeof this.showType != "object") {
                if (this.showType) {
                    this.showType = [String(this.showType)];
                }
            } else {
                this.showType.forEach((item, key) => {
                    this.showType[key] = String(item);
                });
            }
		},
		methods: {
            async loadNode(node, resolve) {
                if (node.level === 0) {
                    let list = await this.getjgList()
                    return resolve(list)
                }else{
                    let list = await this.getjgList(node.data.orgId)
                    return resolve(list)
                }
            },
            filterShow(list){
                let newList = list.filter(item=>{
                    return this.showType.includes(String(item.type))
                })
                newList.forEach((item,k)=>{
                    newList[k] = {
                        ...item,
                        ...item.data,
                    }
                    if(item.children){
                        item.children = this.filterShow(item.children)
                    }
                })
                return newList
            },
			async getjgList(orgId) {
				var url = '/sys/org/queryOrgTree';
                var option = {
                    entryP: 0,
                    orgId:orgId || '',
                }
                var list = []
				await this.$http.post(url,option).then(({ detail }) => {
                    console.log(detail)
                    list = detail[0].children || []
				});
                return this.filterShow(list)
			},
			init() {
				this.dialogVisible = true;
			},

			save(data) {
				console.log(data);
				let selectType = this.selectType
				if (typeof selectType != "object") {
					if (selectType) {
						selectType = [String(selectType)];
					}
				} else {
					selectType.forEach((item, key) => {
						selectType[key] = String(item);
					});
				}
                let disableOrgNum = String(this.disableOrgNum)
                let canChoose = (!disableOrgNum || !data.path.split('/').includes(disableOrgNum))&&(!selectType || selectType.includes(String(data.type)))
                if(canChoose){
                    this.$emit("addInstitutions", data);
                    this.dialogVisible = false;
                }
			},
		},
	};
</script>
